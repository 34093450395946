import { catchError, map, of, tap } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { DEFAULT_SETTINGS, TYPE_TRACKING_DEFAULT, TYPE_TRACKING_PREFERENCE } from '../settings/types';
import { webservice } from './webservice';
import { UNKNOWN } from './types';
import { ORANGEOTT } from '../core/media/types';
import { TV_PLATFORMS } from '../core/dom/types';

const version = global.MAGNETO_VERSION;

export const playerInfo = () => ({
  version,
  env: process.env.NODE_ENV
});

export { scriptLoader } from './scriptLoader';

export { default as Network } from './network';
export { default as Logger } from './logger';
export { default as storage } from './storage';
export { getSessionStorage as sessionStorage } from './storage';
export { default as systemInfo } from './systemInfo';
export { default as preloadImage } from './preloadImage';
export { default as slugify } from './slugify';
export { default as deepMerge } from './deepMerge';
export { default as arrayEncoding } from './arrayEncoding';
export { default as FairPlay } from './fairplay';

export const { requestAPI } = webservice;
export const VOID_FN = (data = {}) => data;

export const svgDefaultDescriptor = {
  xmlns: 'http://www.w3.org/2000/svg',
  preserveAspectRatio: 'xMinYMax meet'
};

export const elementIsWithin = (parent, child) => {
  if (!child || !child.parentElement) return false;
  if (child.parentElement === parent) return true;

  return elementIsWithin(parent, child.parentElement);
};

export const get = (object, path, defaultValue = null) => (
  path
    .split('.')
    .filter((key) => key)
    .reduce((obj, key) => obj && obj[key], object)
  || defaultValue
);

export const getSettingType = (key, currentValue) => (
  get(DEFAULT_SETTINGS, key) === currentValue
    ? TYPE_TRACKING_DEFAULT
    : TYPE_TRACKING_PREFERENCE
);

export const removeQueryParams = (url, list = []) => (list.length
  ? url
    /* remove 1st query params "?query=" */
    .replace(new RegExp(`(\\?${list.join('|\\?')})=((%)?(\\w+)((\\.\\w+)?)+)(&)?`, 'gm'), '?')
    .replace(new RegExp(`(&${list.join('|&')})=((%)?(\\w+)((\\.\\w+)?)+)`, 'gm'), '')
  : url);

export const abort = (controller) => controller.abort();

export const isUrlReachable = (url) => {
  const controller = new AbortController();

  const timeoutId = setTimeout(
    abort.bind(null, controller),
    500
  );

  return fromFetch(url, { method: 'get', signal: controller.signal }).pipe(
    map((response) => [200, 204].includes(response.status)),
    catchError((e) => {
      const isTimeout = e instanceof DOMException && e.name === 'AbortError';

      return of(isTimeout && UNKNOWN);
    }),
    tap(() => { clearTimeout(timeoutId); })
  );
};

export const asPercent = (x, maxValue) => (x / maxValue) * 100;

export const getApiUrlByPlatform = (serviceKey, webservices, platform = '') => {
  switch (platform) {
    case ORANGEOTT:
      return webservices?.mediation?.[serviceKey] || webservices[serviceKey];

    default:
      return webservices[serviceKey];
  }
};

export const openUrl = (url) => window.open(url);
export const selfOrKeyObject = (variable, key) => ((typeof variable) === 'object' ? variable[key] : variable);
export const isTagUnique = (config = {}) => TV_PLATFORMS.includes(config?.platform);

export const getAdjacentPlaylistMedias = ({ playlist: { playlist } } /* store */, id) => {
  const currentIndex = playlist.findIndex(({ src }) => id === src);
  const previousIndex = currentIndex - 1;
  const nextIndex = currentIndex + 1;
  return {
    previousMedia: currentIndex > 0 ? playlist[previousIndex] : undefined,
    previousIndex,
    nextMedia: currentIndex >= 0 && currentIndex < playlist.length ? playlist[nextIndex] : undefined,
    nextIndex
  };
};
