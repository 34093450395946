import { map } from 'rxjs/operators';
import { iso_639_1 as iso639One, iso_639_2 as iso639Two } from 'iso-639';
import { FRENCH_CODE_LIST } from '../ui/components/controls/exp/tracks/constants';

const LABEL_LANG_FRENCH = 'français';
const LABEL_LANG_ORIGINAL = 'version originale';
const LABEL_LANG_FRENCH_AUDIO_DESCRIPTION = 'français';
const LABEL_LANG_FRENCH_DEAF_HARD_HEARING = 'français';
const LABEL_LANG_GERMAN = 'allemand';
const LABEL_LANG_GERMAN_AUDIO_DESCRIPTION = 'allemand';
const LABEL_LANG_GERMAN_DEAF_HARD_HEARING = 'allemand';
const LABEL_LANG_ITALIAN = 'italien';

const LABEL_LANG_FRENCH_AUDIO_DESCRIPTION_FULL = 'français en audio description';
const LABEL_LANG_FRENCH_DEAF_HARD_HEARING_FULL = 'français Sourds et Malentendants';
const LABEL_LANG_GERMAN_AUDIO_DESCRIPTION_FULL = 'allemand en audio description';
const LABEL_LANG_GERMAN_DEAF_HARD_HEARING_FULL = 'allemand Sourds et Malentendants';
const LABEL_LANG_FRENCH_AUTO = 'français (automatique)';
const LABEL_LANG_FRENCH_AUTO_FULL = 'français automatique';

const DEFAULT_FRENCH = { label: LABEL_LANG_FRENCH, ariaLabel: LABEL_LANG_FRENCH };
const DEFAULT_ORIGINAL = { label: LABEL_LANG_ORIGINAL, ariaLabel: LABEL_LANG_ORIGINAL };
const DEFAULT_GERMAN = { label: LABEL_LANG_GERMAN, ariaLabel: LABEL_LANG_GERMAN };
const DEFAULT_ITALIAN = { label: LABEL_LANG_ITALIAN, ariaLabel: LABEL_LANG_ITALIAN };
const DEFAULT_FRENCH_AUDIO_DESCRIPTION = {
  label: LABEL_LANG_FRENCH_AUDIO_DESCRIPTION,
  ariaLabel: LABEL_LANG_FRENCH_AUDIO_DESCRIPTION_FULL,
  icon: 'ad'
};
const DEFAULT_FRENCH_DEAF_HARD_HEARING = {
  label: LABEL_LANG_FRENCH_DEAF_HARD_HEARING,
  ariaLabel: LABEL_LANG_FRENCH_DEAF_HARD_HEARING_FULL,
  icon: 'sme'
};
const DEFAULT_GERMAN_AUDIO_DESCRIPTION = {
  label: LABEL_LANG_GERMAN_AUDIO_DESCRIPTION,
  ariaLabel: LABEL_LANG_GERMAN_AUDIO_DESCRIPTION_FULL,
  icon: 'ad'
};
const DEFAULT_GERMAN_DEAF_HARD_HEARING = {
  label: LABEL_LANG_GERMAN_DEAF_HARD_HEARING,
  ariaLabel: LABEL_LANG_GERMAN_DEAF_HARD_HEARING_FULL,
  icon: 'sme'
};

const DEFAULT_FRENCH_AUTO = {
  label: LABEL_LANG_FRENCH_AUTO,
  ariaLabel: LABEL_LANG_FRENCH_AUTO_FULL
};

const LANGUAGE_LABELS = {
  // French
  fra: DEFAULT_FRENCH,
  fr: DEFAULT_FRENCH,
  fre: DEFAULT_FRENCH,
  qad: DEFAULT_FRENCH_AUDIO_DESCRIPTION,
  qtz: DEFAULT_FRENCH_AUDIO_DESCRIPTION,
  qsm: DEFAULT_FRENCH_DEAF_HARD_HEARING,
  qsa: DEFAULT_FRENCH_AUTO,

  // Multi & Original
  mul: DEFAULT_ORIGINAL,
  qaa: DEFAULT_ORIGINAL,

  // German
  de: DEFAULT_GERMAN,
  ger: DEFAULT_GERMAN,
  qgd: DEFAULT_GERMAN_AUDIO_DESCRIPTION,
  qgs: DEFAULT_GERMAN_DEAF_HARD_HEARING,

  // Others
  it: DEFAULT_ITALIAN
};

export const TRACKS_PRIORITIES = [
  {
    priority: 1,
    languages: ['fr', 'fre', 'fra']
  },
  {
    priority: 2,
    languages: ['qaa', 'en', 'eng', 'ger', 'de', 'it', 'mul']
  },
  {
    priority: 3,
    languages: ['qad', 'qtz', 'qsm', 'qsa', 'qgd', 'qgs']
  }
  // priority 4 for others
];

export const getInferredLangLabel = (isoCode) => {
  const { fr: [inferredLabel] = [] } = { ...iso639One, ...iso639Two }[isoCode?.toLowerCase()] || {};
  return inferredLabel;
};

export const resolveLabel = ({ lang }) => {
  const isoCode = lang?.toLowerCase();

  const knownLanguageLabels = LANGUAGE_LABELS[isoCode];
  if (knownLanguageLabels) return knownLanguageLabels;

  const inferredLabel = getInferredLangLabel(isoCode);
  const labels = inferredLabel ? {
    label: inferredLabel,
    ariaLabel: inferredLabel
  } : DEFAULT_ORIGINAL;

  return labels;
};

export const mapTracks = () => map((tracks) => tracks
  .map(({ lang, index, default: def }) => ({
    index,
    language: lang,
    label: resolveLabel({ lang }),
    default: def
  })));

export const getLanguagePriority = (language) => {
  const { priority } = TRACKS_PRIORITIES.find(({ languages }) => languages.includes(language)) || { priority: 4 };// default value is 4
  return priority;
};

export const isMultilang = (audios, subtitles) => (
  [...audios, ...subtitles].some(({ language }) => (
    language !== null && !FRENCH_CODE_LIST.includes(language)
  ))
);
