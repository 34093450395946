/* eslint-disable react/style-prop-object, react/no-unknown-property */
import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';

function PlaylistIcon({ height = 60, width = 60, ...props }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <mask id="mask0_8328_58884" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="1" y="3" width="30" height="25">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.25 5.83333C9.25 4.4066 10.4066 3.25 11.8333 3.25H27.8424C29.2691 3.25 30.4257 4.4066 30.4257 5.83333V16.9234C30.4257 18.3502 29.2691 19.5068 27.8424 19.5068H26.5878V21.1256C26.5878 22.5523 25.4312 23.7089 24.0045 23.7089H22.4257V25.3277C22.4257 26.7545 21.2691 27.9111 19.8424 27.9111H3.83333C2.4066 27.9111 1.25 26.7545 1.25 25.3277V14.2376C1.25 12.8109 2.4066 11.6543 3.83333 11.6543H5.41211V10.0355C5.41211 8.60875 6.56871 7.45215 7.99544 7.45215H9.25V5.83333ZM6.91211 11.6543H19.8424C21.2691 11.6543 22.4257 12.8109 22.4257 14.2376V22.2089H24.0045C24.6028 22.2089 25.0878 21.7239 25.0878 21.1256V10.0355C25.0878 9.43717 24.6028 8.95215 24.0045 8.95215H7.99544C7.39713 8.95215 6.91211 9.43717 6.91211 10.0355V11.6543ZM27.8424 18.0068H26.5878V10.0355C26.5878 8.60875 25.4312 7.45215 24.0045 7.45215H10.75V5.83333C10.75 5.23503 11.235 4.75 11.8333 4.75H27.8424C28.4407 4.75 28.9257 5.23502 28.9257 5.83333V16.9234C28.9257 17.5217 28.4407 18.0068 27.8424 18.0068ZM2.75 14.2376C2.75 13.6393 3.23502 13.1543 3.83333 13.1543H19.8424C20.4407 13.1543 20.9257 13.6393 20.9257 14.2376V25.3277C20.9257 25.926 20.4407 26.4111 19.8424 26.4111H3.83333C3.23502 26.4111 2.75 25.926 2.75 25.3277V14.2376Z" fill="white" />
      </mask>
      <g mask="url(#mask0_8328_58884)">
        <rect width="32" height="32" fill="black" />
        <rect width="32" height="32" fill="white" />
      </g>
    </SVGWrapper>
  );
}

export default PlaylistIcon;
