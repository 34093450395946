/* eslint-disable react/no-unknown-property, react/style-prop-object */
import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';

function PlaylistActiveIcon({ height = 60, width = 60, ...props }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <mask id="mask0_8758_5933" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="2" y="4" width="28" height="24">
        <path d="M27.8424 4H11C10.4477 4 10 4.44772 10 5C10 5.55228 10.4453 6 10.9976 6H24.5C27.3 6 28 8.66667 28 10V17.9189C28 18.3817 28.3751 18.7568 28.8378 18.7568C29.3006 18.7568 29.6757 18.3817 29.6757 17.9189V5.83333C29.6757 4.82081 28.8549 4 27.8424 4Z" fill="#D9D9D9" />
        <path d="M24.0045 8.20215H7.06104C6.56457 8.20215 6.16211 8.60461 6.16211 9.10107C6.16211 9.59754 6.56457 10 7.06104 10H20.5C21.6667 10 24 10.8 24 14V22.0406C24 22.5481 24.4114 22.9589 24.9189 22.9589C25.4264 22.9589 25.8378 22.5475 25.8378 22.04V10.0355C25.8378 9.02296 25.017 8.20215 24.0045 8.20215Z" fill="#D9D9D9" />
        <path d="M3.83333 12.4043C2.82081 12.4043 2 13.2251 2 14.2376V25.3277C2 26.3403 2.82081 27.1611 3.83333 27.1611H19.8424C20.8549 27.1611 21.6757 26.3403 21.6757 25.3277V14.2376C21.6757 13.2251 20.8549 12.4043 19.8424 12.4043H3.83333Z" fill="#D9D9D9" />
        <path d="M27.8424 4H11C10.4477 4 10 4.44772 10 5C10 5.55228 10.4453 6 10.9976 6H24.5C27.3 6 28 8.66667 28 10V17.9189C28 18.3817 28.3751 18.7568 28.8378 18.7568C29.3006 18.7568 29.6757 18.3817 29.6757 17.9189V5.83333C29.6757 4.82081 28.8549 4 27.8424 4Z" stroke="white" />
        <path d="M24.0045 8.20215H7.06104C6.56457 8.20215 6.16211 8.60461 6.16211 9.10107C6.16211 9.59754 6.56457 10 7.06104 10H20.5C21.6667 10 24 10.8 24 14V22.0406C24 22.5481 24.4114 22.9589 24.9189 22.9589C25.4264 22.9589 25.8378 22.5475 25.8378 22.04V10.0355C25.8378 9.02296 25.017 8.20215 24.0045 8.20215Z" stroke="white" />
        <path d="M3.83333 12.4043C2.82081 12.4043 2 13.2251 2 14.2376V25.3277C2 26.3403 2.82081 27.1611 3.83333 27.1611H19.8424C20.8549 27.1611 21.6757 26.3403 21.6757 25.3277V14.2376C21.6757 13.2251 20.8549 12.4043 19.8424 12.4043H3.83333Z" stroke="white" />
      </mask>
      <g mask="url(#mask0_8758_5933)">
        <rect width="32" height="32" fill="black" />
        <rect width="32" height="32" fill="white" />
      </g>
    </SVGWrapper>
  );
}

export default PlaylistActiveIcon;
